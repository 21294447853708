import React from "react";
import classNames from "./blogmenubar.module.scss";
import { BsDiscord, BsTelegram, BsInstagram } from "react-icons/bs";
import { RiLinkedinFill, RiWhatsappFill, RiFileCopyLine } from "react-icons/ri";

const BlogMenuBar = ({ saved, saveArticle, saving, copyy }) => {
  return (
    <div className={classNames.blogMenuBar}>
      {saved ? (
        <div className={classNames.primaryButton}>Already Saved</div>
      ) : (
        <div
          className={classNames.primaryButton}
          onClick={(e) => saveArticle()}
        >
          {saving ? "Saving..." : saved ? "Already Saved" : "Save Article"}
        </div>
      )}
      <div className={classNames.socialMediaIcons}>
        <div>
          <BsDiscord />
        </div>
        <div>
          <BsTelegram />
        </div>
        <div>
          <BsInstagram />
        </div>
        <div>
          <RiLinkedinFill />
        </div>
        <div>
          <RiWhatsappFill />
        </div>
        <div
          onClick={() =>
            navigator.clipboard.writeText(
              "https://web3today.io/news/article/" + copyy
            )
          }
        >
          <RiFileCopyLine />
        </div>
      </div>
    </div>
  );
};

export default BlogMenuBar;
