export const ImagesCS = {
  appMenu: require("./app-menu.svg").default,
  attach: require("./attach.svg").default,
  banking: require("./banking.svg").default,
  canadaFlag: require("./canada-flag.svg").default,
  WAPPs: require("./companies.svg").default,
  cryptoStartupFull: require("./crypto-startup-full.svg").default,
  cryptoStartupShort: require("./crypto-startup-short.svg").default,
  events: require("./events.svg").default,
  influencer: require("./influencers.svg").default,
  investors: require("./investors.svg").default,
  rankings: require("./rankings.svg").default,
  regulations: require("./regulation.svg").default,
  share: require("./share.svg").default,
  tools: require("./tools.svg").default,
  trending: require("./trending.svg").default,
  wishlist: require("./wishlist.svg").default,
  founders: require("./founders.svg").default,
  myCryptoBrand: require("./mycryptobrand-full.svg").default,
  teamForce: require("./team-force-full.svg").default,
  instaLegal: require("./insta-legal-full.svg").default,
  search: require("./search.svg").default,
  corona: require("./corona.png").default,
  playIcon: require("./play-icon.svg").default,
  girlFace: require("./girl.svg").default,
  doneLogo: require("./done-logo.svg").default,
  meetingStream: require("./meeting-stream.svg").default,
  closeLogo: require("./close-logo.svg").default,
  cryptoStartupText: require("./crypto-s-text.svg").default,
  thickArrow: require("./thick-arrow.svg").default,
  fullScreen: require("./full-screen.svg").default,
  searchDark: require("./search-dark.svg").default,
  worldWide: require("./worldwide.svg").default,
  brainLogo: require("./brain-logo.svg").default,

  showPassword: require("./show-password.svg").default,
  doNotShowPassword: require("./dont-show.svg").default,
  bosLogo: require("./bos-logo.svg").default,
  cover: require("./cover.png").default,
  shorupan: require("./shorupan.svg").default,
  coin: require("./coin.svg").default,
  hamburger: require("./hamburger.svg").default,
  shareIcon: require("./share-icon.svg").default,
  playIconWhite: require("./play-icon-white.svg").default,

  //NavbarImages
  newsico: require("./NavImages/thenews.svg").default,
  editorpicksico: require("./NavImages/thestar.svg").default,
  businessico: require("./NavImages/thebriefcase.svg").default,
  technologyico: require("./NavImages/thesettings.svg").default,
  nftico: require("./NavImages/thenftico.svg").default,
  coinico: require("./NavImages/thecoin.svg").default,
  defiico: require("./NavImages/thedefi.svg").default,
  gameico: require("./NavImages/thegame.svg").default,
  marketico: require("./NavImages/themarkets.svg").default,
  opinionico: require("./NavImages/theopinion.svg").default,
  barsimg: require("./NavImages/barsimg.svg").default,
  closeimg: require("./NavImages/closeiconn.svg").default,

  //Article Images
  articleico: require("./BlogImgs/thearticles.svg").default,
  dogeico: require("./BlogImgs/dogeico.svg").default,
  bullishico: require("./BlogImgs/bullishico.svg").default,
  rightarrowico: require("./BlogImgs/rightarrowico.svg").default,
  timerico: require("./BlogImgs/timerico.svg").default,
  favouriteico: require("./BlogImgs/favoriteico.svg").default,

  //Sidebar
  sideico: require("./BlogImgs/css.svg").default,

  //SocialIcons
  twitterico: require("./BlogImgs/twitterico.svg").default,
  facebookico: require("./BlogImgs/facebookico.svg").default,
  instaico: require("./BlogImgs/instaico.svg").default,
  discordico: require("./BlogImgs/discordico.svg").default,
  socialico: require("./BlogImgs/socialico.svg").default,
  linkedinico: require("./BlogImgs/linkedinico.svg").default,
  telegramico: require("./BlogImgs/telegramico.svg").default,
  podcastico: require("./BlogImgs/podcstico.svg").default,
  youtubeico: require("./BlogImgs/youtubeico.svg").default,

  //ArticleIcons
  bitcoinico: require("./ArticleImgs/bitcoinimgg.svg").default,
  chartico: require("./ArticleImgs/chartico.svg").default,
  ethico: require("./ArticleImgs/ethico.svg").default,
  ethchartico: require("./ArticleImgs/ethchartico.svg").default,
  binanceico: require("./ArticleImgs/binanceico.svg").default,
  binancechartico: require("./ArticleImgs/binancechartico.svg").default,
  authorprofile: require("./ArticleImgs/authorprf.svg").default,
  calico: require("./ArticleImgs/calico.svg").default,
  mainartimg: require("./ArticleImgs/mainartimg.svg").default
};
