import React, { useContext, useEffect, useState } from "react";

import classNames from "./questions.module.scss";
import { GlobalContex } from "../../globalContext";
import axios from "axios";

const Questions = ({ allQuestions }) => {
  return (
    <div>
      {allQuestions?.length > 0 &&
        allQuestions?.map((eachQues, index) => {
          return (
            <EachQuesAnswer {...eachQues} key={eachQues?.question + index} />
          );
        })}
    </div>
  );
};

export default Questions;

const EachQuesAnswer = ({ question, options, _id }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [pointsGot, setPointsGot] = useState("");
  const [gotResponse, setGotResponse] = useState("");
  const { userProfile } = useContext(GlobalContex);

  const responses = [
    {
      title: "Congratulations",
      response1:
        "You have selected the correct answer and you have claimed earned ",
      response2: " W3T Point From This Question.",
      balance1: "Previous Balance",
      balance2: "Updated Balance",
      btn1: "Go To Players App",
      btn2: "Keep Earning",
    },
    {
      title: "Nice Try",
      response1:
        "But you have already tried to answer the question for this article. Unfortunately you can only submit one answer",
      btn1: "Close",
    },
    {
      title: "Don’t Worry",
      response1:
        "Unfortunately that is not the correct answer. Worry not, there are many more articles and questions for you to try again.",
      btn1: "Close",
    },
  ];

  function submitAnswer() {
    // setGotResponse(responses[2]);
    let obj = {
      user_id: userProfile?._id,
      question_id: _id,
      publication_id: "638dd769b257b3715a8fbe07",
      answer: selectedAnswer,
    };
    // console.log(obj, "answer objj");

    axios
      .post("https://publications.apimachine.com/userarticleanswers/new", obj)
      .then((response) => {
        let res = response?.data;
        // console.log(response?.data, "submit answer response");
        if (!res?.status) {
          setGotResponse(responses[1]);
        } else if (res?.status && res?.data?.is_correct) {
          setGotResponse(responses[0]);
          setPointsGot(res?.data?.points);
        } else if (res?.status && !res?.data?.is_correct) {
          setGotResponse(responses[2]);
        }
      })
      .catch((error) => {
        console.log(error?.message, "submit answer error");
      });
  }

  useEffect(() => {
    if (selectedAnswer) {
      submitAnswer();
    }
  }, [selectedAnswer]);

  return (
    <div className={classNames.eachQuesAnswer}>
      <div className={classNames.title}>{question}</div>
      <div className={classNames.options}>
        {options?.length > 0 &&
          options?.map((eachoption, index) => {
            return (
              <div
                key={eachoption?.option + index}
                onClick={() => {
                  setSelectedAnswer(eachoption?.option);
                }}
              >
                {index + 1 + ". "}
                {eachoption?.option}
              </div>
            );
          })}
      </div>
      {gotResponse?.title == "Congratulations" ? (
        <div className={classNames.resultDiv}>
          <div className={classNames.result}>
            <div className={classNames.title}>{gotResponse?.title}</div>
            <div className={classNames.response}>
              {gotResponse?.response1 + pointsGot
                ? pointsGot
                : "" + gotResponse?.response2}
            </div>
            <div className={classNames.balances}>
              <div>
                <span>{gotResponse?.balance1}</span>
                <span>0.00</span>
              </div>
              <div>
                <span>{gotResponse?.balance2}</span>
                <span>0.00</span>
              </div>
            </div>
            <div
              className={classNames.btnDiv}
              style={{ flexDirection: "column" }}
            >
              <div className={classNames.purpleBtn}>{gotResponse?.btn1}</div>
              <div className={classNames.blackBtn}>{gotResponse?.btn2}</div>
            </div>
          </div>
          <div
            className={classNames.overlay}
            onClick={() => setGotResponse("")}
          ></div>
        </div>
      ) : gotResponse ? (
        <div className={classNames.resultDiv}>
          <div className={classNames.result}>
            <div className={classNames.title}>{gotResponse?.title}</div>
            <div className={classNames.response}>{gotResponse?.response1}</div>
            <div className={classNames.btnDiv}>
              <div
                className={classNames.purpleBtn}
                onClick={() => setGotResponse("")}
              >
                {gotResponse?.btn1}
              </div>
            </div>
          </div>
          <div
            className={classNames.overlay}
            onClick={() => setGotResponse("")}
          ></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
