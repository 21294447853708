import React, { useState, lazy, Suspense, useEffect, useContext } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import "./App.scss";
import styles from "./assets/scss/main.module.scss";

import HomepageOld from "./Pages/HomePageOld/homepage";
import Articles from "./Pages/Articles/articles";
import DevPage from "./Pages/DevPage/devpage";
import MainPage from "./Pages/Mainpage/mainpage";
import HomePage from "./Pages/HomePage";
import RegistrationContextProvider from "./RegistrationContext";
import RegisterHomePage from "./Pages/Registration/pages/HomePage";
import FirstPage from "./Pages/Registration/pages/FirstPage";
import ArticlePage from "./Pages/ArticlePage";
import MarketsPage from "./Pages/MarketsPage";
import VideoPage from "./Pages/VideoPage";
import LoginPage from "./Pages/LoginPage";
import { GlobalContex } from "./globalContext";
import Lock from "./assets/LockIcon.svg";
import { ReactComponent as Collapse_img } from "./assets/images/icons/collapse.svg";
import { ReactComponent as Collapse1_img } from "./assets/images/icons/collapse1.svg";
import ScoreBoard from "./Pages/ScoreBoard";
import useWindowDimensions from "./services/WindowSize";

import MyProfile from "./Pages/MyProfile";
import Settings from "./Pages/Settings";


// import MobileSidebarMenu from "./component/MobileSidebar";

import Ads from "./Pages/Player";
 import LoginApp from "./component/modellogin/Logins";
function App() {
  const history = useHistory();
  const { location } = useLocation();
  const { width, height } = useWindowDimensions();
  const {
    setShowPopup,
    loginData,
    collapse,
    setCollapse,
    selectedApp,
    mobileMenu,
    hideArrow,
    userProfile,
    setUserProfile,
    registerUser,
    setRegisterUser,
  } = useContext(GlobalContex);

  useEffect(() => {
    document
      .querySelectorAll("*")
      .forEach((element) =>
        element.addEventListener("scroll", ({ target }) => setShowPopup(false))
      );

    // window.addEventListener("scroll", handlePopup);
    return () =>
      window.removeEventListener("scroll", ({ target }) => setShowPopup(false));
  }, []);

  return (
    <>
      {collapse ? <div className={styles.overlay}>&nbsp;</div> : ""}
      <RegistrationContextProvider>
        {!mobileMenu ? (
          <>
            <div className="App">
              {loginData || registerUser ? (
                ""
              ) : (
                <div
                  style={{
                    position: "fixed",
                    inset: "0",
                    zIndex: "2",
                  }}
                >
                  <LoginApp /> 
                  
                  {/* */}
                  <div
                    onClick={() => {
                      setRegisterUser("closeLogin");
                    }}
                    style={{
                      position: "absolute",
                      inset: "0",
                      zIndex: "1",
                      background: "#000000bf",
                    }}
                  ></div>
                </div>
              )}
              {loginData && width > 700 ? (
                <div className={styles.loggedInContainer}>
                  <div
                    className={styles.profilePic}
                    style={{ width: "50px", height: "50px" }}
                  >
                    <img
                      src={
                        userProfile
                          ? userProfile?.profile_pic
                          : loginData.user.profile_img
                      }
                      alt="profile"
                    />
                  </div>
                  <div
                    className={styles.logout}
                    onClick={(e) => {
                      localStorage.clear();
                      window.location.reload();
                      history.push("/news/articles");
                    }}
                  >
                    <img src={Lock} alt="Logout" />
                  </div>
                </div>
              ) : (
                ""
              )}
              <Switch>
                <Route exact path="/" component={Ads} />
                {/* <Route
                  exact
                  path="/scoreboard"
                  component={loginData ? ScoreBoard : HomePage}
                /> */}
                <Route exact path="/scoreboard">
                  {!loginData ? (
                    <Redirect to="/news/articles" />
                  ) : (
                    <ScoreBoard />
                  )}
                </Route>
                <Route exact path="/settings">
                  {!loginData ? <Redirect to="/news/articles" /> : <Settings />}
                </Route>
                <Route exact path="/settings/myprofile">
                  {!loginData ? (
                    <Redirect to="/news/articles" />
                  ) : (
                    <MyProfile />
                  )}
                </Route>

                <Route exact path="/savedItems" component={HomePage} />
                {/* <Route exact path="/createProfile" component={CreateProfile} /> */}
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/news/articles" component={HomePage} />
                <Route exact path="/news/WAPPs" component={HomePage} />
                <Route exact path="/news/videos" component={HomePage} />
                <Route exact path="/news/people" component={HomePage} />
                <Route exact path="/news/research" component={HomePage} />
                <Route exact path="/news/events" component={HomePage} />
                {/* <Route exact path="/markets" component={MarketsPage} /> */}
                <Route exact path="/markets/crypto" component={MarketsPage} />
                <Route exact path="/markets/forex" component={MarketsPage} />
                <Route
                  exact
                  path="/markets/regulation"
                  component={MarketsPage}
                />
                <Route exact path="/markets/people" component={MarketsPage} />
                <Route exact path="/markets/research" component={MarketsPage} />
                <Route exact path="/markets/events" component={MarketsPage} />
                <Route exact path="/page1" component={DevPage} />
                <Route exact path="/page2" component={MainPage} />
                <Route exact path="/page3" component={HomepageOld} />
                <Route exact path="/articles" component={Articles} />
                {/* <Route
              exact
              path="/:country"
              render={() => (
                <CStartupProvider>
                  <MainLandingPage />
                </CStartupProvider>
              )}
            /> */}
            
                <Route exact path="/:name/:video" component={Ads} />
                <Route exact path="/:name" component={Ads} />
                <Route exact path="/earn/ads" component={Ads} />
                <Route
                  exact
                  path={`/news/article/:id`}
                  component={ArticlePage}
                />
                <Route exact path={`/news/video/:id`} component={VideoPage} />
                <Route exact path="/register" component={RegisterHomePage} />
                <Route exact path="/register/affiliate" component={FirstPage} />
                <Route
                  exact
                  path="/register/affiliate/:id"
                  component={FirstPage}
                />
                <Route
                  exact
                  path="/register/pre-registered"
                  component={FirstPage}
                />
                <Route
                  exact
                  path="/register/pre-registered/:id"
                  component={FirstPage}
                />
                <Route exact path="/register/by-myself" component={FirstPage} />
                <Route
                  exact
                  path="/register/by-myself/:id"
                  component={FirstPage}
                />

                {/* <Redirect
              to={`/${
                !localStorage.getItem("country")
                  ? "publicationtemplate2"
                  : localStorage.getItem("country")
              }/trending`}
            /> */}
              </Switch>
            </div>
            {loginData && width > 700 ? (
              <div
                style={{ left: collapse ? "268px" : "68px" }}
                className={styles.collapseButton}
                onClick={(e) => setCollapse(!collapse)}
              >
                {collapse ? (
                  <Collapse_img fill={"#4B2A91"} stroke="none" />
                ) : (
                  <Collapse1_img fill={"#4B2A91"} stroke="none" />
                )}
              </div>
            ) : (
              ""
            )}
            {loginData && width > 700 ? (
              collapse ? (
                <div className={styles.sideDraw}>
                  <div className={styles.leftSide}>
                    <div>
                      <div
                        className={styles.profilePic}
                        onClick={(e) => {
                          setCollapse(!collapse);
                          history.push("/news/articles");
                        }}
                      >
                        <img
                          src={
                            userProfile
                              ? userProfile?.profile_pic
                              : loginData.user.profile_img
                          }
                          alt="profile"
                        />
                      </div>
                      <div className={styles.nameStyle}>
                        {userProfile
                          ? userProfile?.first_name
                          : loginData.user.name.length > 15
                          ? loginData.user.name.slice(0, 12) + "..."
                          : loginData.user.name}
                      </div>
                      <div className={styles.emailStyle}>
                        {userProfile
                          ? userProfile?.email
                          : loginData.user.email.length > 25
                          ? loginData.user.email.slice(0, 22) + "..."
                          : loginData.user.email}
                      </div>
                      <div className={styles.menuWrapper}>
                        <div
                          className={styles.menuItem}
                          onClick={(e) => {
                            setCollapse(!collapse);
                            history.push("/savedItems");
                          }}
                        >
                          Saved Items
                        </div>
                        <div className={styles.menuItem}>Connections</div>
                        <div className={styles.menuItem}>Applications</div>
                        <div className={styles.menuItem}>Communities</div>
                        <div className={styles.menuItem}>Alerts</div>
                      </div>
                      <div
                        className={styles.statWrapper}
                        onClick={(e) => {
                          history.push("/scoreboard");
                          setCollapse(!collapse);
                        }}
                        style={{
                          borderBottom: "1px solid #E7E7E7",
                          padding: "17px 0px",
                        }}
                      >
                        <div className={styles.numberStyle}>0.00</div>
                        <div className={styles.numberLableStyle}>
                          Web3Today Score
                        </div>
                      </div>
                      <div
                        className={styles.statWrapper}
                        style={{
                          borderBottom: "1px solid #E7E7E7",
                          padding: "17px 0px",
                        }}
                      >
                        <div className={styles.numberStyle}>0.00</div>
                        <div className={styles.numberLableStyle}>
                          W3T Token Balance
                        </div>
                      </div>
                    </div>
                    <div className={styles.buttonGroup}>
                      <div className={styles.primaryButton}>Earn More W3T</div>
                      <div
                        className={styles.secondaryButton}
                        onClick={(e) => {
                          setCollapse(!collapse);
                          // history.push("/settings");
                        }}
                      >
                        Settings
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSide}>&nbsp;</div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </>
        ) : (
          <>
          </>
          // <MobileMenu />
          // <MobileSidebarMenu />
        )}
      </RegistrationContextProvider>
    </>
  );
}

export default App;
