import React, { useState } from "react";
import classNames from "./blogsidemenu.module.scss";
import { TiSocialLinkedin, TiSocialTwitter } from "react-icons/ti";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

const Index = ({
  type,
  publisherData,
  publisherArticles,
  selectedFilter,
  setSelectedFilter,
  publisherLoading,
  filters,
}) => {
  return (
    <div className={classNames.blogSideMenu}>
      <div className={classNames.filterType}>
        <div
          onClick={(event) => {
            console.log(event, "Same Author");
            setSelectedFilter(event.target.innerText);
          }}
          style={{
            borderBottom:
              selectedFilter == "Same Author"
                ? "1px solid #4B2A91"
                : "1px solid #E7E7E7",
          }}
        >
          Same Author
        </div>
        <div
          onClick={(event) => {
            setSelectedFilter(event.target.innerText);
          }}
          style={{
            borderBottom:
              selectedFilter == "Same Category"
                ? "1px solid #4B2A91"
                : "1px solid #E7E7E7",
          }}
        >
          Same Category
        </div>
      </div>
      <div className={classNames.card}>
        <div className={classNames.profileImg}>
          <img
            src={
              selectedFilter == "Same Author" && publisherData?.profile_pic
                ? publisherData?.profile_pic
                : selectedFilter == "Same Category" && filters?.thumbnail
                ? filters?.thumbnail
                : ""
            }
            alt=""
          />
        </div>
        <div className={classNames.details}>
          <div>
            {selectedFilter == "Same Author" && publisherData?.name
              ? publisherData?.name
              : selectedFilter == "Same Category" && filters?.title
              ? filters?.title
              : ""}
          </div>
          <div>
            {selectedFilter == "Same Author"
              ? "View Profile"
              : selectedFilter == "Same Category" && type == "video"
              ? `All ${filters?.title ? filters?.title : ""} Videos`
              : `All ${filters?.title ? filters?.title : ""} Articles`}
          </div>
        </div>
        <div
          className={classNames.socialHandles}
          style={{ display: selectedFilter == "Same Author" ? "" : "none" }}
        >
          <TiSocialLinkedin />
          <TiSocialTwitter />
        </div>
      </div>
      <div className={classNames.contentDiv}>
        {publisherLoading
          ? Array(6)
              .fill("")
              .map((_, i) => {
                return <EachCardContentDivLoading />;
              })
          : publisherArticles?.length > 0
          ? publisherArticles?.map((eachArticle) => {
              return <EachCardContentDiv {...eachArticle} type={type} />;
            })
          : ""}
      </div>
    </div>
  );
};

export default Index;

const EachCardContentDiv = ({
  title,
  icon,
  createdAt,
  link_name,
  type,
  image,
  _id,
  custom_url,
}) => {
  const history = useHistory();
  return (
    <div
      className={classNames.eachCardContentDiv}
      onClick={() => {
        if (type == "video") {
          history.push(`/news/video/${custom_url ? custom_url : ""}`);
        } else {
          history.push(`/news/article/${custom_url ? custom_url : ""}`);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <div className={classNames.imgContainer}>
        <img src={icon ? icon : image ? image : ""} alt="imgContainer" />
      </div>
      <div className={classNames.contentContainer}>
        <div>{title ? title : ""}</div>
        <div>{createdAt ? moment(createdAt).startOf("day").fromNow() : ""}</div>
      </div>
    </div>
  );
};
const EachCardContentDivLoading = () => {
  return (
    <div className={classNames.eachCardContentDiv}>
      <div className={classNames.imgContainer}>
        <Skeleton circle width={50} height={50} />
      </div>
      <div className={classNames.contentContainer}>
        <div>
          <Skeleton width={250} height={10} />
        </div>
        <div>
          <Skeleton width={150} height={10} />
        </div>
      </div>
    </div>
  );
};
