import React, { useContext, useEffect } from "react";
import classNames from "./mobilesidebar.module.scss";

//assets
import MainIco1 from "../../assets/mainico.svg";
import { AiOutlineClose } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import searchIco from "../../assets/NavImages/thesearch.svg";
import { Link } from "react-router-dom";
import { GlobalContex } from "../../globalContext";

const Index = () => {
  const {
    mobileMenu,
    setMobileMenu,
    selectedMobileMenu,
    setSelectedMobileMenu,
  } = useContext(GlobalContex);

  return (
    <div className={classNames.mobileSidebarMenu}>
      <div className={classNames.mobileNavbar}>
        <div onClick={() => setMobileMenu(false)}>
          <AiOutlineClose />
        </div>
        <Link
          to="/news/articles"
          onClick={() => {
            setSelectedMobileMenu("News");
            setMobileMenu(false);
          }}
        >
          <img src={MainIco1} alt="MainIco1" />
        </Link>
        <div>
          {/* <BiSearchAlt2 /> */}
          <img src={searchIco} alt="searchIco" />
        </div>
      </div>

      <div className={classNames.options}>
        <Link
          to="/news/articles"
          className={
            selectedMobileMenu == "News" ? classNames.selectedOption : ""
          }
          onClick={() => {
            setSelectedMobileMenu("News");
            setMobileMenu(false);
          }}
        >
          Articles
        </Link>
        <Link
          to="/news/videos"
          className={
            selectedMobileMenu == "Videos" ? classNames.selectedOption : ""
          }
          onClick={() => {
            setSelectedMobileMenu("Videos");
            setMobileMenu(false);
          }}
        >
          Videos
        </Link>
        <Link
          to="/news/wapps"
          className={
            selectedMobileMenu == "WAPPS" ? classNames.selectedOption : ""
          }
          onClick={() => {
            setSelectedMobileMenu("WAPPS");
            setMobileMenu(false);
          }}
        >
          WAPPS
        </Link>
        <Link
          to="/markets/crypto"
          className={
            selectedMobileMenu == "Markets" ? classNames.selectedOption : ""
          }
          onClick={() => {
            setSelectedMobileMenu("Markets");
            setMobileMenu(false);
          }}
          style={{ pointerEvents: "none", opacity: "0.5" }}
        >
          Markets
        </Link>
        <Link
          className={
            selectedMobileMenu == "Earn" ? classNames.selectedOption : ""
          }
          to="/earn/ads"
          // style={{ pointerEvents: "none", opacity: "0.5" }}
          onClick={() => {
            setSelectedMobileMenu("Earn");
            setMobileMenu(false);
          }}
        >
          Earn
        </Link>
      </div>

      <div className={classNames.mainBtns}>
        <Link
          to="#"
          onClick={() => {
            setMobileMenu(false);
            window.open("https://app.web3today.io/", "_blank");
          }}
        >
          Login
        </Link>
        <Link
          to="#"
          onClick={() => {
            setMobileMenu(false);
            window.open("https://app.web3today.io/", "_blank");
          }}
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default Index;
