import React, { useEffect, useState } from "react";
import classNames from "./articlestickyfooter.module.scss";
import axios from "axios";

const ArticleStickyFooter = ({ type }) => {
  const [rewardPointFooter, setRewardPointFooter] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://publications.apimachine.com/publication/638dd769b257b3715a8fbe07`
      )
      .then((response) => {
        setRewardPointFooter(response?.data?.data?.rewardPoints);
      })
      .catch((error) => {
        console.log(error?.message, "Publication footer error");
      });
  }, []);

  return (
    <div className={classNames.articleStickyFooter}>
      <div>
        <span>
          {type == "video"
            ? "You Can Earn 2 W3T Points Per Minute For Watching This Video"
            : `You Can Earn ${rewardPointFooter ? rewardPointFooter : ""}
          W3T Points Per Minute For Reading This Article`}
        </span>
        <span
          onClick={(e) => {
            window.location.href = "https://app.web3today.io/";
          }}
        >
          Start Earning Now
        </span>
      </div>
      <div>
        <span>
          {type == "video"
            ? "Earn Another 15 W3T Points For"
            : `Earn Another ${
                rewardPointFooter ? rewardPointFooter : ""
              } W3T Points
          For`}
        </span>
        <span
          onClick={(e) => {
            window.location.href = "https://app.web3today.io/";
          }}
        >
          {type == "video" ? "Answering Questions" : "Finishing The Article"}
        </span>
      </div>
    </div>
  );
};

export default ArticleStickyFooter;
