import React, { useContext } from "react";
import styles from "./mobileNav.module.scss";

import MenuIco from "../../../assets/MobileAssets/menu.svg";
import MainIco from "../../../assets/mainico1.svg";
import searchIco from "../../../assets/NavImages/thesearch.svg";
import { GlobalContex } from "../../../globalContext";
import useWindowDimensions from "../../../services/WindowSize";
import { Link } from "react-router-dom";

const MobileNav = () => {
  const { mobileMenu, setMobileMenu, selectedStory, setSelectedMobileMenu } =
    useContext(GlobalContex);
  const { width, height } = useWindowDimensions();
  return (
    <>
      {width < 700 ? (
        <div
          className={styles.navContainer}
          style={{ filter: selectedStory ? "blur(70px)" : "none" }}
        >
          <div onClick={(e) => setMobileMenu(true)}>
            <img
              src={MenuIco}
              alt=""
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <Link
            to="/news/articles"
            onClick={() => {
              setSelectedMobileMenu("News");
              setMobileMenu(false);
            }}
          >
            <img
              src={MainIco}
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
          </Link>
          <div>
            <img
              src={searchIco}
              alt=""
              style={{ width: "25px", height: "25px" }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MobileNav;
